import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import '../styles/booking.css';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getSportIcon } from '../helper/racqyHelper';
import {
  sendBookingEmails,
  setBookingDetail,
} from '../redux/slices/bookingSlice';
import BookingWrapper from '../components/BookingSucessfull/BookingWrapper/index';
import Button from '../components/Checkout/Button';
import { validateEmail } from '../utils/validations';
import BookingDetail from '../components/BookingSucessfull/BookingDetail/index';
import locationIconSvg from '../../src/assets/icons/location.svg';
import calendarSvg from '../../src/assets/icons/date.svg';
import clockSvg from '../../src/assets/icons/time.svg';
import priceSvg from '../../src/assets/icons/price.svg';
import tennisPng from '../../src/assets/bookingDone.png';
import ShareBookingModal from '../components/BookingSucessfull/ShareBookingModal';
import Input from '../components/Checkout/Input';
import logo from '../images/drawer_ic.svg';

export default function Bookings({ location, params }) {
  const dispatch = useDispatch();
  const bookingDetail = useSelector(
    (state) => state?.bookingReducer?.bookingDetail
  );

  const shareBookingDetail = useSelector(
    (state) => state?.bookingReducer?.shareBookingDetailInfo
  );
  const isLoading = useSelector((state) => state?.bookingReducer?.isLoading);
  const [email, setEmail] = useState('');
  const [disable, setDisable] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  useEffect(() => {
    if (!bookingDetail) {
      navigate('/404/');
    }
    return () => dispatch(setBookingDetail(null));
  }, []);

  const timeConversion = (time) => {
    return moment(time).format('HH:mm');
  };
  const onTextChange = (e) => {
    const { value } = e.target;
    let isEmailValid = validateEmail(value);
    setDisable(isEmailValid ? false : true);
    setEmail(value);
  };

  const handleShareBookingModal = () => {
    setShareModalOpen(!shareModalOpen);
  };
  const onShare = () => {
    dispatch(
      sendBookingEmails({
        payload: {
          ...shareBookingDetail,
          emails: [email],
          sendEmailToOwner: false,
        },
        showSuccessAlert: true,
      })
    );
    setEmail('');
  };
  return (
    <Layout location={location}>
      <Helmet>
        <title>BookingDetail</title>
      </Helmet>
      <div style={{ height: 70 }} />

      <div className="main-container-bookings">
        <div className="inner-container-bookings">
          <div onClick={() => navigate('/')} className="toolBar cursor-pointer">
            <img src={logo} className="logo" />
            <p>Go to startpage</p>
          </div>
          <div className="flex">
            <div className="leftDiv-bookings">
              <div>
                <h2>Done! Your booking</h2>

                <h2>is confirmed.</h2>
              </div>
              <div className="mt-4 mb-8">
                <p className="text-xs">
                  You will soon receive a confirmation email
                </p>
                <p className="text-xs">with your booking details</p>
              </div>

              <div className="border-dashed border-[1.2px] md:p-4 rounded-md md:w-full p-2">
                <BookingWrapper>
                  <BookingDetail
                    imgSource={locationIconSvg}
                    label={'Venue'}
                    value={
                      bookingDetail?.clubName || bookingDetail?.clubInfo?.name
                    }
                  />

                  <BookingDetail
                    imgSource={getSportIcon(
                      bookingDetail?.sports?.value ||
                        bookingDetail?.clubInfo?.sports[0],
                      true
                    )}
                    label={'Booking'}
                    value={
                      bookingDetail?.clubInfo?.sports[0] ||
                      bookingDetail?.courtName +
                        ', ' +
                        bookingDetail?.sports?.label ||
                      ''
                    }
                  />
                </BookingWrapper>
                <BookingWrapper>
                  <BookingDetail
                    imgSource={calendarSvg}
                    label={'Date'}
                    value={moment(bookingDetail?.dateTime).format(
                      'dddd, DD MMM YYYY'
                    )}
                  />

                  <BookingDetail
                    imgSource={clockSvg}
                    label={'Time'}
                    value={
                      bookingDetail?.endTime
                        ? `${
                            timeConversion(bookingDetail?.startTime) +
                            ' - ' +
                            timeConversion(bookingDetail?.endTime)
                          }`
                        : timeConversion(bookingDetail?.selectedSlot?.dateTime)
                    }
                  />
                </BookingWrapper>
                <BookingWrapper>
                  <BookingDetail
                    imgSource={priceSvg}
                    label={'Price'}
                    value={`${
                      bookingDetail?.selectedSlot?.price
                        ? bookingDetail?.selectedSlot?.price
                        : bookingDetail?.price
                    } ${bookingDetail?.currency?.name}`}
                  />
                  <div className="md:w-[45%] w-full  flex justify-center md:mt-0 mt-2">
                    <div className="w-full">
                      <Button
                        onClick={handleShareBookingModal}
                        title="Share booking details "
                      />
                    </div>
                  </div>
                </BookingWrapper>
              </div>
            </div>
            <div className="rightDiv-bookings">
              <img
                src={tennisPng}
                style={{ height: 300, width: 300, objectFit: 'contain' }}
              />
            </div>
          </div>
        </div>
        {shareModalOpen && (
          <ShareBookingModal
            customStyle={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onRequestClose={handleShareBookingModal}
          >
            <div className="flex w-full h-full flex-col items-center">
              <h4 className="text-base mb-12 mt-20">Share Booking Details</h4>
              <div className="md:px-8 w-full px-2">
                <Input
                  value={email}
                  name="email"
                  onChange={(e) => onTextChange(e)}
                  placeholder="email"
                />

                <Button
                  loading={isLoading}
                  classes={`${disable ? 'opacity-50' : ''} h-8 mt-8`}
                  title="Share"
                  onClick={onShare}
                  disabled={disable || isLoading}
                />
              </div>
              <div className=" flex h-full w-full flex-col justify-end items-end ">
                <div className="flex justify-center items-center bg-racqy-modal-footer h-16 w-full ">
                  <p className="text-racqy-slide-over-label  cursor-pointer max-sm:text-[12px] md:text-sm ">
                    Don’t have an account?{' '}
                    <a onClick={() => navigate('/download')}>Download app</a>
                  </p>
                </div>
              </div>
            </div>
          </ShareBookingModal>
        )}
      </div>
    </Layout>
  );
}
