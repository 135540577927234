import React from 'react';

export default function index({ imgSource, label, value }) {
  return (
    <div className="flex items-center md:mt-0 mt-4 md:w-[45%] break-words ">
      <div className="bg-racqy-light-blue md:w-12 md:h-10 w-14 h-10 rounded-lg md:mr-4 mr-2 flex justify-center items-center">
        <img className="square-box-image-bookings" src={imgSource} />
      </div>
      <div className="md:w-[100%] w-[100%]">
        <p className={`text-xs text-racqy-text-light`}>{label}</p>
        <p className="text-sm  mt-1 ">{value}</p>
      </div>
    </div>
  );
}
