import React from 'react';

const Input = ({ placeholder, className, ...otherProps }) => {
  return (
    <input
      placeholder={placeholder}
      className={`border-[1px] border-gray-200 w-full py-2 px-3 rounded-md placeholder-gray-400 text-xs outline-none ${className}`}
      {...otherProps}
    />
  );
};

export default Input;
