import React, { useState } from 'react';
import ReactModal from 'react-modal';
import close from '../../../images/icons/close.svg';

ReactModal.setAppElement('#___gatsby');

// markup
const ShareBookingModal = (props) => {
  const [modalIsOpen, setIsOpen] = useState(true);

  function requestClose() {
    props.onRequestClose();
    // setIsOpen(false);
  }

  return (
    <>
      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="Checkout modal"
        className="bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[50%] sm:max-md:p-0  outline-none rounded-lg overflow-hidden md:h-[340px] w-[90%] lg:w-[32%] md:w-[60%] "
        overlayClassName="fixed inset-0 z-[200] bg-racqy-overlay md:p-6 p-2"
      >
        <div style={props.customStyle} className="w-full relative h-full">
          <button
            onClick={requestClose}
            className="absolute md:right-5 right-4 top-5 cursor-pointer"
          >
            <img src={close} className="w-3 h-3" />
          </button>
          {props.children}
        </div>
      </ReactModal>
    </>
  );
};

export default ShareBookingModal;
